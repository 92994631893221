import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Alert, Button, Heading, Separator, Typography, cn } from '@kiiwi/ui';
import Link from 'next/link';
import AppointmentInfoBar from '@/components/organisms/AppointmentInfoBar/AppointmentInfoBar';
import LoginForm from './LoginForm';
import { useTranslation } from '@/src/i18n';
import { useIsPartners } from '@/src/hooks/useIsPartners';
import { APP_ROUTES } from '@/src/constants';

import HeadingSection from './HeadingSection';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import { isValidTemporaryAppointment } from '@/components/utils/appointment';
import { useGetTemporaryAppointment } from '@/src/hooks/appointments/useTemporaryAppointment';

const LoginRegister = () => {
  const router = useRouter();
  const isWhiteLabel = useIsPartners();
  const { t } = useTranslation();
  const temporaryAppointment = useGetTemporaryAppointment();
  const [fromDocavenue, setFromDocavenue] = React.useState(false);

  const nextPage = router.query?.next;
  const isTeleconsultation = router.query?.isTeleconsultation === 'true';

  // In case the user is redirected to the login page when he's taking an appointment,
  // we have to display a specific message to inform him why he arrived here.
  const isTakingAppointment = nextPage
    ?.toString()
    .startsWith(APP_ROUTES.APPOINTMENT_CONFIRMATION);

  const withTempRdv = useMemo(
    () =>
      temporaryAppointment && isValidTemporaryAppointment(temporaryAppointment),
    [temporaryAppointment],
  );
  const showFlowRDV = withTempRdv || router?.query?.fromPreferences;

  React.useEffect(() => {
    if (window.sessionStorage.getItem('fromDocavenue')) {
      setFromDocavenue(true);
    }
  }, []);

  return (
    <div
      className={clsx(
        'mt-[--header-bar] min-h-[--content-height]',
        showFlowRDV
          ? 'relative mx-auto flex flex-col items-center justify-start bg-white pt-6 md:pt-8'
          : 'grid w-full bg-surface-lighter md:grid-cols-[447px_1fr]',
      )}
    >
      <div
        className={cn(
          'flex w-full flex-col border-r border-neutral bg-white [&_.appointment-info-bar]:!bg-transparent',
          showFlowRDV
            ? 'mb-8 max-w-[653px] rounded-lg border max-md:mx-4'
            : 'border-r',
        )}
      >
        {withTempRdv && (
          <AppointmentInfoBar appointment={temporaryAppointment!} />
        )}
        <div className="flex grow flex-col justify-between p-6 max-md:px-4">
          {nextPage?.includes('waiting-proposals') && (
            <Alert variant="info" className="mb-4">
              <Typography>{t('login-to-confirm-waiting-proposal')}</Typography>
            </Alert>
          )}

          {isTeleconsultation && (
            <Alert variant="info" className="mb-4">
              <Typography>{t('login-to-go-to-tlc')}</Typography>
            </Alert>
          )}

          {fromDocavenue && (
            <Alert variant="info" className="mb-4">
              <div className="space-y-4">
                <Typography>{t('patient-docavenue')}</Typography>
                <Link href={APP_ROUTES.REGISTER} passHref>
                  <Button
                    className="w-full"
                    variant="primary"
                    color="macro"
                    data-cy="btn-register-docavenue-user"
                  >
                    {t('create-account-maiia')}
                  </Button>
                </Link>
              </div>
            </Alert>
          )}

          {router?.query?.fromPreferences && (
            <Alert variant="info" className="mb-4">
              <Typography>{t('connect_to_add_favorite')}</Typography>
            </Alert>
          )}
          <HeadingSection />
          {isTakingAppointment && (
            // In case the user is redirected to the login page when he's taking an appointment,
            // we have to display a specific message to inform him why he arrived here.
            <Alert className="mb-4" variant="warning">
              <Typography>{t('login_to_finish_appointment')}</Typography>
            </Alert>
          )}
          <div className="mb-4 grow text-neutral-primary">
            <Heading variant="title-2" as="h1" className="mb-8">
              {isWhiteLabel ? t('login-title-white-label') : t('log-in')}
            </Heading>
            <LoginForm />
            <Separator className="my-6" />
            <Typography
              variant="body-2"
              className="mb-3 text-center text-information-alert-text"
            >
              {t('no-account-yet')}
            </Typography>
            <Button
              className="w-full"
              variant="secondary"
              color="brand"
              data-cy="btn-register"
              aria-label={t('create-account')}
              title={t('create-account')}
              asChild
            >
              <Link
                href={{
                  pathname: APP_ROUTES.REGISTER,
                  query: router.query,
                }}
                onClick={() => {
                  analyticsEvent({
                    category: 'Authentication',
                    action: 'GoToCreateAccount',
                  });
                }}
                legacyBehavior={false}
              >
                {t('create-account')}
              </Link>
            </Button>
          </div>
        </div>
      </div>
      {!showFlowRDV && (
        <div
          className="flex h-full grow items-center justify-center bg-surface-light-brand bg-contain bg-center bg-no-repeat bg-origin-content p-[8vw] max-md:hidden max-md:w-0"
          style={{
            backgroundImage:
              'url("/static/images/pages_illu/login-register.svg")',
          }}
        />
      )}
    </div>
  );
};

export default LoginRegister;
