import React, {
  ChangeEventHandler,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  forwardRef,
} from 'react';
import {
  FormField,
  FormLabel,
  FormMessage,
  Input,
  InputAddon,
} from '@kiiwi/ui';
import { FieldProps, getIn } from 'formik';
import { useTranslation } from '@/src/i18n';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

export type TextInputProps = {
  label?: ReactNode;
  addon?: ReactNode;
  hint?: ReactNode;
  rightIcon?: ReactNode;
  type?: HTMLInputTypeAttribute;
  toUpperCase?: boolean;
  stripSpaces?: boolean;
  invalid?: boolean;
} & FieldProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'form'>;

const TextInput = forwardRef(function TextInput(
  {
    field,
    form,
    meta,
    label,
    addon,
    hint,
    type,
    toUpperCase,
    stripSpaces,
    invalid,
    ...props
  }: TextInputProps,
  ref: Ref<HTMLInputElement>,
) {
  const { t } = useTranslation();
  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);

  const handleChange: ChangeEventHandler<HTMLInputElement> = event => {
    // Function parameter cannot be reassign directly.
    const alteredEvent = event;

    if (toUpperCase) {
      alteredEvent.target.value = alteredEvent.target.value.toUpperCase();
    }
    // This was done to improve user experience with inputs that should not contain leading or trailing spaces, like email inputs for exemple.
    if (stripSpaces) {
      alteredEvent.target.value = alteredEvent.target.value.trim();
    }
    field.onChange(alteredEvent);
  };

  return (
    <FormField invalid={(touched && error) || invalid}>
      {label && <FormLabel>{label}</FormLabel>}
      <Input
        {...{
          ...field,
          ...props,
        }}
        onChange={handleChange}
        type={type ?? 'text'}
        ref={ref}
      >
        {addon ? <InputAddon placement="right">{addon}</InputAddon> : undefined}
      </Input>
      {touched && error ? (
        <FormMessage className="flex items-center gap-1">
          <FontAwesomeIcon
            name="circle-xmark:regular"
            className="w-4 shrink-0 text-danger-alert-icon"
          />
          {t(error)}
        </FormMessage>
      ) : (
        hint && (
          <FormMessage className="flex items-center gap-1 text-information-alert-text">
            <FontAwesomeIcon
              name="circle-info:regular"
              className="w-4 shrink-0 text-information-alert-icon"
            />
            {hint}
          </FormMessage>
        )
      )}
    </FormField>
  );
});

export default TextInput;
