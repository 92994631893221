import React from 'react';
import { Form, Formik, Field, FormikHelpers, FieldProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '@docavenue/core';
import * as yup from 'yup';
import { useRouter } from 'next/router';
import { Alert, Button, Typography } from '@kiiwi/ui';
import Link from 'next/link';
import RequestErrorAlert from '@/components/molecules/RequestErrorAlert/RequestErrorAlertKiiwi';
import { useTranslation } from '@/src/i18n';

import { APP_ROUTES } from '@/src/constants';
import useSSOUserData from '@/src/hooks/useSSOUserData';
import useAuthentication from '@/src/hooks/useAuthentication';
import { LoginFormValues } from '@/src/types/api';
import TextInput from '@/components/molecules/Input/TextInput';
import PasswordInput from '@/components/molecules/Input/PasswordInputKiiwi';

const validationSchema = yup.object().shape({
  password: yup.string().required('error:registration_form_password_required'),
  email: yup
    .string()
    .email('error:invalide_format_email')
    .required('error:registration_form_username_required'),
});

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const error = useSelector(
    (state: any) => state?.errors?.authentication_create,
  );
  // TODO: Refactor useAuthentication to use Tanstack Query.
  const { loginWithCredentials } = useAuthentication();

  // When "formSubmissionDisabled", the form submission is disabled, we targeting "errors" resource and
  // not "authentication" because on "errorActions.removeError" it will clear the error
  // from the global "errors" resource
  const formSubmissionDisabled = useSelector(
    (state: any) =>
      // eslint-disable-next-line camelcase
      state?.errors?.authentication_create?.code === 'USER_TEMPORARILY_BLOCKED',
  );

  const { data: ssoData } = useSSOUserData();

  const initialValues = ssoData?.ssoProfile
    ? { email: ssoData.ssoProfile.email, password: '' }
    : { email: '', password: '' };
  const isUnlocked = router.query.unlocked;

  const handleSubmit = async (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>,
  ) => {
    try {
      setSubmitting(true);
      await loginWithCredentials(values);
    } catch (err) {
      // Handled with saga errors and in login method
    } finally {
      setSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (error) {
      dispatch(
        errorActions.removeError({
          resource: 'authentication',
          crudActionType: 'create',
        }),
      );
    }
  }, []);

  const invalidCredentials = error?.code === 'AUTH_ERROR_001';

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form method="POST" className="active flex w-full flex-col">
          {isUnlocked && !error && (
            <Alert variant="success" data-cy="form-error" className="mb-4">
              {t('unlock_account_success')}
            </Alert>
          )}
          {error && error.code !== 'MFA_NEEDED' && !invalidCredentials && (
            <RequestErrorAlert
              resource="authentication"
              action="CREATE"
              className="mb-4"
            />
          )}
          <div className="mb-8 space-y-4">
            <Field name="email">
              {(props: FieldProps) => (
                <TextInput
                  {...props}
                  type="text"
                  data-cy="login-mail"
                  label={`${t('mail')}*`}
                  placeholder={t('email_example_placeholder')}
                  stripSpaces
                  required
                  invalid={invalidCredentials}
                />
              )}
            </Field>
            <Field name="password">
              {(props: FieldProps) => (
                <PasswordInput
                  {...props}
                  data-cy="login-password"
                  label={`${t('password')}*`}
                  placeholder={t('enter_password')}
                  invalid={invalidCredentials}
                />
              )}
            </Field>

            <Button
              variant="ghost"
              color="neutral"
              data-cy="btn-login-forgot-pwd"
              asChild
            >
              <Link href={APP_ROUTES.FORGOT_PASSWORD} legacyBehavior={false}>
                {t('forgotten-password-?')}
              </Link>
            </Button>

            {invalidCredentials && (
              <Typography className="text-center text-body-2 text-danger-alert-text">
                {t('error:invalid_email_and_password')}
              </Typography>
            )}
          </div>

          <Button
            className="w-full"
            type="submit"
            variant="primary"
            color="macro"
            data-cy="submit-login"
            disabled={formSubmissionDisabled || isSubmitting}
            title={t('login')}
            aria-label={t('login')}
          >
            {t('login')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
