import {
  Appointment,
  User,
} from '@maiia/model/generated/model/api-patient/api-patient';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serialActions, asyncActions } from '@docavenue/core';
import { useRouter } from 'next/router';
import { get, isEmpty } from 'lodash';
import {
  appointmentsActions,
  authActions,
  usersActions,
  temporaryAppointmentsActions,
} from '../../actions';
import { useApplicationType } from '@/src/hooks';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import useBranding from '@/src/hooks/branding/useBranding';
import { enqueueSnackbar } from '@/src/actions/snackbar';
import { useTranslation } from '@/src/i18n';
import { APP_ROUTES } from '@/src/constants';
import { screebEventTrack } from '@/src/screeb';

export type TemporaryAppointment = Appointment & {
  availability: any;
  documentToShareIds?: string[];
};

// TODO: remove profileId and use appointment.profile.id
type Props = {
  appointment: TemporaryAppointment;
  profileId?: string;
  onError?: Function;
};

export type CreateAppointmentOptions = {
  enableAnalytics?: boolean;
  conflictingApptToDelete?: string;
  addSubstituteData?: boolean;
  series?: boolean;
  successSnackbar?: boolean;
};

const useTemporaryAppointment = ({
  appointment,
  profileId,
  onError,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const applicationType = useApplicationType();
  const router = useRouter();
  const { data: branding } = useBranding();
  const oldAppointment = useSelector(state =>
    get(state, 'appointments.items[0]'),
  );

  const createAppointment = React.useCallback(
    ({
      enableAnalytics = true,
      conflictingApptToDelete,
      addSubstituteData = false,
      series = false,
      successSnackbar = false,
    }: CreateAppointmentOptions) => {
      const {
        practitionerId,
        centerId,
        consultationReasonId,
        startDate,
        endDate,
        type,
        availability,
        profileId: appointmentProfileId,
        comment,
        documents,
      } = appointment;

      dispatch(
        serialActions.serial([
          // !Important! Keep profileId in temporaryAppointments state for not break anything
          // !note -> onChange must set in temporary and not only confirmAppointment, just if user refresh it's the function -> setTemporaryAppointmentValue
          () =>
            temporaryAppointmentsActions.mergeItem({
              profileId: profileId || appointmentProfileId, // profileId from props or use appointment data
            }),
          () => {
            const action = appointmentsActions.create(
              {
                startDate,
                endDate,
                profileId,
                practitionerId,
                centerId,
                consultationReasonId,
                applicationType,
                type,
                comment,
                documentToShareIds: documents?.map(document => document.id),
                teleconsultationRelayId: branding?.id,
                ...(conflictingApptToDelete && {
                  appointmentIdToDelete: conflictingApptToDelete,
                }),
                ...(addSubstituteData === true &&
                availability?.substitute?.userId
                  ? {
                      substituteData: {
                        ...availability?.substitute,
                      },
                    }
                  : {}),
              },
              false,
              {
                aggregateWith: 'cardHcd',
              },
            );
            action.onSuccess = () => {
              if (successSnackbar === true) {
                dispatch(
                  enqueueSnackbar({
                    message: t('referral-appointment-confirmed-alert'),
                    duration: 5000,
                    severity: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                  }),
                );
              }
            };
            action.onError = async err => {
              onError?.();
              // eslint-disable-next-line no-console
              console.log(
                'error wow',
                Object.getOwnPropertyNames(err).reduce((acc, key) => {
                  acc[key] = err[key];
                  return acc;
                }, {}),
              );
            };
            return action;
          },
          () => temporaryAppointmentsActions.resetItem(),
          async state => {
            // Refetch profile for reload healhcare team
            const currentUser = state?.authentication?.item;
            const user = await asyncActions(
              dispatch,
              usersActions.getOne('me'),
            );
            return authActions.setItem({
              ...currentUser,
              ...user,
            });
          },
          state => {
            const createdAppointement = state?.appointments
              ?.item as Appointment;
            const currentUser = state?.authentication?.item as User;

            if (!createdAppointement) {
              // we should never pass here
              router.replace({
                pathname: APP_ROUTES.RDV,
              });
              return;
            }

            if (enableAnalytics === true) {
              const eventName = series
                ? 'ClickAppointmentSeries'
                : 'ClickConfirmAppointment';
              analyticsEvent({
                category: 'Booking',
                action: eventName,
              });

              // conversion for google ads
              window.gtag?.('event', 'conversion', {
                send_to: `${
                  (window as any).tarteaucitron?.user?.googleadsId
                }/FU6pCPDwsIoDEM_-4L0C`,
              });
              const lastAlgoliaTrackInfo = window.sessionStorage?.getItem(
                'lastTrackInfo',
              );
              if (lastAlgoliaTrackInfo) {
                const trackInfo = JSON.parse(lastAlgoliaTrackInfo);
                try {
                  window.aa?.('convertedObjectIDsAfterSearch', {
                    eventName,
                    ...trackInfo,
                  });
                  // eslint-disable-next-line no-empty
                } catch {}
              }
              screebEventTrack('Appointment confirmed');
            }

            // if the ASSISTED_PATIENT user has an account the appointement
            // will not be visible in the pseudo-account so we do a redirection
            // to the home page, a modal will appear to inform the user
            if (
              currentUser?.role?.name === 'ASSISTED_PATIENT' &&
              currentUser.id !== createdAppointement.userId
            ) {
              router.replace({
                pathname: APP_ROUTES.HOME,
                query: {
                  referralAppointmentWithAccount: 'true',
                },
              });
            } else {
              router.replace({
                pathname: APP_ROUTES.APPOINTMENT.ID,
                query: {
                  id: createdAppointement.id,
                  success: 'true',
                  ...(series ? { series: 'true' } : {}),
                },
              });
            }
          },
        ]),
      );
    },
    [
      applicationType,
      appointment,
      branding?.id,
      dispatch,
      oldAppointment?.id,
      onError,
      profileId,
      router,
    ],
  );

  return { createAppointment };
};

export function useGetTemporaryAppointment(): TemporaryAppointment | undefined {
  const temporaryAppointment = useSelector(
    (state: any) => state?.temporaryAppointments?.item,
  ) as TemporaryAppointment;
  return !isEmpty(temporaryAppointment) ? temporaryAppointment : undefined;
}

export default useTemporaryAppointment;
