import React, { useCallback } from 'react';

import { IconButton } from '@kiiwi/ui';
import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';
import { useTranslation } from '@/src/i18n';
import TextInput, { TextInputProps } from './TextInput';

type Props = {
  canShowPassword?: boolean;
} & TextInputProps;

const PasswordInput = ({ canShowPassword = true, ...props }: Props) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleTogglePasswordVisibility = useCallback(() => {
    setShowPassword(value => !value);
  }, []);
  const handleMouseDownPassword = useCallback(event => {
    // Prevent focusing the input when clicking on visibility button.
    event.preventDefault();
  }, []);

  return (
    <TextInput
      {...props}
      type={canShowPassword && showPassword ? 'text' : 'password'}
      addon={
        <>
          {props.addon}
          {canShowPassword ? (
            <IconButton
              variant="ghost"
              aria-label={
                showPassword
                  ? t('form_account_password_hide')
                  : t('form_account_password_display')
              }
              onClick={handleTogglePasswordVisibility}
              onMouseDown={handleMouseDownPassword}
              size="sm"
            >
              <FontAwesomeIcon
                name={showPassword ? 'eye:regular' : 'eye-slash:regular'}
              />
            </IconButton>
          ) : (
            undefined
          )}
        </>
      }
    />
  );
};

export default PasswordInput;
